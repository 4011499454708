import number from '../components/inputFields/number';
import date from '../components/inputFields/date';
import readonly from '../components/inputFields/readOnly';

import { useAppStore } from '../store/app';
import { useDomainStore } from '../store/domain';
import { useNuxtStore } from '../store/nuxt';
import { useFFStore } from '../store/ffStore';

import { giveIPAFieldList, disburseLoanFieldList, ipaFormFields, declineReasons, declineCategory, declineSubCategory } from './localeConfig';

export default {
  components: { number, date, readonly },
  data () {
    return {
      errorFields: {},
      warningFields: {},
      offerObjValid: false,
      offerObj: {},
      rejectReason: undefined,
      rejectCategory: undefined,
      rejectSubCategory: undefined,
      rejectTexts: undefined,
      rejectReasonError: false,
      rejectReasonOther: '',
      rejectReasonOtherError: false,
      customerVisitToBranch: undefined,
      customerVisitToBranchError: false,
      appStore: useAppStore(),
      domainStore: useDomainStore(),
      nuxtStore: useNuxtStore(),
      ffStore: useFFStore(),
    }
  },
  computed: {
    hkRejectReasonRevampFeatureFlags () {
      return this.ffStore.getFeatureFlag('4846');
    },
    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },
    miscTexts () {
      return this.domainStore.miscTexts();
    },
    offerFieldsText () {
      return this.domainStore.offerFieldsText();
    },
    rejectionReasons () {
      return this.domainStore.rejectionReasons();
    },
    rejectionCategories () {
      return this.domainStore.rejectionCategories();
    },
    rejectionSubCategories () {
      return this.domainStore.rejectionSubCategories();
    },
    domains () {
      return this.domainStore.getDomains();
    },
    getDeclineSubCategory () {
      const options = [];
      if (declineSubCategory[this.locale][this.rejectCategoryModel]) {
        declineSubCategory[this.locale][this.rejectCategoryModel].forEach(reason => options.push({ value: reason, text: this.getRejectReasonsLabel(reason) }));
      }
      options.push({ value: undefined, text: this.getTexts('defaultDropdownOption') });
      this.rejectSubCategory = options;

      // eslint-disable-next-line no-unused-expressions
      const hasSubCategories = (this.rejectSubCategory).some(category => category.value === this.rejectReasonModel)
      if (!hasSubCategories) {
        this.rejectReasonModel = undefined;
      }
      return options;
    },
    getDeclineOptions () {
      // const options = [{ value: undefined, text: this.getTexts('defaultDropdownOption') }];
      const options = [];

      if (this.locale === 'hk' && !this.hkRejectReasonRevampFeatureFlags) {
        if (this.panel === 'offerRevision') {
          declineReasons[this.locale][this.panel][this.currentSubTab].forEach(reason => options.push({ value: reason, text: this.getRejectReasonsLabel(reason) }));
        } else {
          declineReasons[this.locale][this.panel].forEach(reason => options.push({ value: reason, text: this.getRejectReasonsLabel(reason) }));
        }
      } else {
        declineCategory[this.locale].forEach(reason => options.push({ value: reason, text: this.getRejectReasonsLabel(reason) }));
      }

      // if (this.locale) declineReasons[this.locale].forEach(reason => options.push({ value: reason, text: this.getRejectReasonsLabel(reason) }));
      options.push({ value: undefined, text: this.getTexts('defaultDropdownOption') });
      this.rejectTexts = options;
      return options;
    },
    fields () {
      const list = (['lead', 'offerRevision'].includes(this.panel)) ? giveIPAFieldList[this.locale] : disburseLoanFieldList[this.locale];
      const fields = {};
      list.forEach((item) => {
        fields[item] = { ...ipaFormFields[this.locale][item] };
        if (ipaFormFields[this.locale][item].onChange) {
          fields[item].onChange = [];
          ipaFormFields[this.locale][item].onChange.forEach(f => fields[item].onChange.push(ipaFormFields[this.locale].functions[f]));
          // fields[item].onChange.push(ipaFormFields[this.locale].functions[ipaFormFields[this.locale][item].onChange]);
        }
      });

      return fields;
    },
    customerVisitToBranchModel: {
      get () {
        return this.customerVisitToBranch;
      },
      set (val) {
        if (this.customerVisitToBranchError) this.customerVisitToBranchError = false;
        this.customerVisitToBranch = val;
      }
    },
    rejectReasonModel: {
      get () {
        return this.rejectReason;
      },
      set (val) {
        if (this.rejectReasonError) this.rejectReasonError = false;
        this.rejectReason = val;
      }
    },
    rejectCategoryModel: {
      get () {
        return this.rejectCategory;
      },
      set (val) {
        if (this.rejectReasonError) this.rejectReasonError = false;
        this.rejectCategory = val;
      }
    },
    otherRejectReasonModel: {
      get () {
        return this.rejectReasonOther;
      },
      set (val) {
        if (this.rejectReasonOtherError) this.rejectReasonOtherError = false;
        this.rejectReasonOther = val;
      }
    },
    language () {
      return this.nuxtStore.language;
    },
    locale () {
      return this.nuxtStore.locale;
    },
    yesNoOptions () {
      const { yesNoOptions } = this.domains;

      return [
        { value: undefined, text: this.getTexts('defaultDropdownOption') },
        ...yesNoOptions
      ];
    },
    isNotOnlineLender () {
      return this.appStore.onlineLender === false;
    },
    currentSubTab () {
      return this.appStore.currentSubTab;
    }
  },
  methods: {
    getFieldLabel (index) {
      if (this.offerFieldsText[index]) return this.offerFieldsText[index].label;
      return index;
    },
    getTexts (index) {
      if (this.miscTexts[index]) return this.miscTexts[index].label;
      return index;
    },
    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    },
    getRejectReasonsLabel (index) {
      if (this.rejectionCategories[index]) {
        return this.rejectionCategories[index].label;
      }

      if (this.rejectionSubCategories[index]) {
        return this.rejectionSubCategories[index].label;
      }

      if (this.rejectionReasons[index]) {
        return this.rejectionReasons[index].label;
      };
      return index;
    },
    validateOfferObj () {
      this.offerObjValid = true;

      // if none are filled
      if (Object.keys(this.offerObj).length === 0) {
        this.offerObjValid = false;
        return Object.keys(this.fields).forEach((i) => {
          this.errorFields[i] = true;
        });
      }

      // validate each field
      Object.keys(this.fields).forEach((i) => {
        if (['appliedAmount', 'processingFee', 'monthlyCost', 'nettLoanAmount'].includes(i) && typeof this.offerObj[i] === 'string') this.offerObj[i] = Number(this.offerObj[i].replace(/,/g, ''));
        switch (this.fields[i].type) {
          case 'number':
            // fail
            if (this.offerObj[i] === undefined || isNaN(this.offerObj[i])) {
              this.offerObjValid = false;
              return this.errorFields[i] = true;
            }

            if (this.locale === 'sg' && i === 'processingFee') {
              const tempAppliedAmount = this.offerObj.appliedAmount;
              if (tempAppliedAmount && (this.offerObj[i] < tempAppliedAmount * 0.01 || this.offerObj[i] > tempAppliedAmount * 0.1)) {
                this.offerObjValid = false;
                return this.errorFields[i] = true;
              }
            }

            if (['appliedAmount', 'monthlyCost', 'loanPeriod'].includes(i) && !Number(this.offerObj[i])) {
              this.offerObjValid = false;
              return this.errorFields[i] = true;
            }

            // pass
            this.errorFields[i] = false;
            this.offerObj[i] = Number(this.offerObj[i]);
            break;

          case 'date':
            if (!this.validatedDate(this.offerObj[i])) this.offerObjValid = false;
            this.errorFields[i] = !this.validatedDate(this.offerObj[i]);
            break;
        }
      });

      if (!this.offerObjValid) return false;
      return true;
    },
    isRejectionRemarkRequired(category, reason) {
      switch (reason) {
        case 'other':
          // this specific check is for old rejection reason format
          return true;
        case 'others':
          return category === 'lender_policy';
        case 'weird_behaviour':
          return category === 'profile_issue';
        default:
          return false;
      }
    },
    validateRejectReason () {
      if (!this.rejectReason) {
        this.rejectReasonError = true;
        return false;
      }

      const isRemarkRequiredForRejection = this.isRejectionRemarkRequired(this.rejectCategory, this.rejectReason);
      const hasNoRemark = !(/\S/.test(this.rejectReasonOther)) || this.rejectReasonOther === undefined;

      if (isRemarkRequiredForRejection && hasNoRemark) {
        this.rejectReasonOtherError = true;
        return false;
      }

      return true;
    },
    validateCustomerCameToBranch () {
      if (!this.isNotOnlineLender) return true;

      this.customerVisitToBranchError = this.customerVisitToBranch === undefined;

      return !this.customerVisitToBranchError;
    },
    validatedDate (date) {
      const dateArr = String(date).split('-');
      const [yyyy, mm, dd] = dateArr;
      const today = new Date();
      const todayObj = {
        date: today.getDate(),
        month: today.getMonth() + 1,
        year: today.getYear() + 1900
      }

      if (Number(dd) > 31 || Number(dd) < 1 || dd == 'undefined') return false;
      if (Number(mm) > 12 || Number(mm) < 1 || mm == 'undefined') return false;
      if (yyyy.length !== 4 || yyyy == 'undefined') return false;

      if (yyyy > todayObj.year) return false;
      if (yyyy < 1900) return false;
      if (yyyy == todayObj.year) {
        if (mm > todayObj.month) return false;
        if (mm == todayObj.month) {
          if (dd > todayObj.date) return false;
        }
      }

      return true // if all pass
    }
  },
  mounted () {
    const settings = this.appStore.currentProductSettings();
    if (this.locale === 'hk' && settings.defaultLoanFeeEnabled) {
      this.offerObj.processingFee = settings.defaultLoanFeePercentage;
    }
  },
}
